/* Container for the hover effect */
.hover_container {
  position: relative;
  width: 100%;
  max-width: 312px;
  height: auto;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease, z-index 0.3s ease;
}

@media (min-width: 768px) {
  .hover_container {
    height: 406px;
  }
}

/* Image inside the hover container */
.hover_image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without stretching */
  transition: transform 0.3s ease, z-index 0.3s ease;
  position: center;
}

/* Hover effect on the container */
.hover_container:hover {
  transform: scale(1.05);
  z-index: 10;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Overlay with text and button */
.hover_overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 13.54%,
    rgba(0, 0, 0, 0.99) 73.96%
  );
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 16px;
  color: white;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

@media (min-width: 768px) {
  .hover_overlay {
    padding: 24px;
    font-size: 20px;
    line-height: 25.7px;
  }
}

/* Main text styling */
.hover_overlay .main_text {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: white;
  margin-bottom: 8px;
  text-align: center;
}

@media (min-width: 768px) {
  .hover_overlay .main_text {
    font-size: 20px;
    line-height: 25.7px;
    margin-bottom: 10px;
  }
}

/* Additional text styling */
.hover_overlay .additional_text {
  width: 100%;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.85px;
  text-align: left;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .hover_overlay .additional_text {
    font-size: 12px;
    line-height: 15.42px;
  }
}

.hover_overlay .readMore {
  width: 70px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.85px;
  text-align: left;
}

@media (min-width: 768px) {
  .hover_overlay .readMore {
    font-size: 12px;
    line-height: 15.42px;
  }
}

.hover_container:hover .hover_overlay {
  opacity: 1;
}
