.container {
  max-width: 1000px;
  margin: auto;
  padding: 30px;
  margin-top: -50px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
}

.label {
  margin-top: 20px;
  margin-bottom: 10px;
}

.input {
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  font-size: 16px;
  width: 100%;
  border: none;
  box-sizing: border-box;
  background: #fafafa;
}

.select {
  padding: 8px;
  font-size: 16px;
  width: 100%;
  border: none;
  box-sizing: border-box;
  background: #fafafa;
}

.nextButton,
.styledButton {
  width: 40%;
  margin-top: 2%;
  padding: 10px;
  background-color: #61dafb;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.nextButton:hover,
.styledButton:hover {
  background-color: #21a1f1;
}

.addButton {
  padding: 8px;
  margin-top: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.addButton:hover {
  background-color: #45a049;
}

.removeButton {
  padding: 8px;
  margin-top: 10px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.removeButton:hover {
  background-color: #e53935;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.friendContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.responsive_heading {
  font-family: "Poppins";
  font-weight: bold;
  font-size: 30px;
}

@media (max-width: 479px) {
  .responsive_heading {
    font-size: 18px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .responsive_heading {
    font-size: 22px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .responsive_heading {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .responsive_heading {
    font-size: 30px;
  }
}
