.contactContainer {
  text-align: left;
  margin-top: 32px;
  width: 50%;
  margin: 0 auto;
}

.contactButton {
  width: 162px;
  height: 43px;
  padding: 8px 21px 8px 23px;
  border: 0;
  border-radius: 24px;
  background: rgba(232, 246, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
}

.contactButtonText {
  color: rgba(5, 84, 242, 1);
  width: 118px;
  height: 27px;
  opacity: 1;
}

.contact {
  width: 100%;
  margin-top: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.contactHeading {
  color: black;
  font-family: Montserrat;
  font-size: 46px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  white-space: nowrap;
}

.contactSubheading {
  font-family: Montserrat;
  font-size: 46px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgba(5, 84, 242, 0.45);
  white-space: nowrap;
}

.contactDescription {
  width: 100%;
  margin-top: 23px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.contactDescription span {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

.contactItem {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.locationImg {
  width: 36px;
  height: 64px;
  margin-right: 10px;
}

.locationDiscriptions {
  font-family: Montserrat;
  font-size: 19px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin-left: 30px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  flex: 1;
}

.phoneIcon {
  width: 36px;
  height: 29px;
  margin-right: 10px;
}

.contactItem img {
  max-width: 50%;
  height: 43px;
  margin-right: 10px;
}

/* Media Queries */

/* For screens smaller than 1440px */
@media (max-width: 1440px) {
  .contactContainer {
    width: 70%;
  }
  .contactHeading,
  .contactSubheading {
    font-size: 40px;
  }
}

/* For screens smaller than 1028px */
@media (max-width: 1028px) {
  .contactContainer {
    width: 80%;
  }
  .contactHeading,
  .contactSubheading {
    font-size: 36px;
  }
  .locationImg,
  .phoneIcon {
    width: 30px;
    height: 48px;
  }
  .locationDiscriptions {
    font-size: 17px;
  }
}

/* For screens smaller than 768px */
@media (max-width: 768px) {
  .contactContainer {
    width: 90%;
  }
  .contactHeading,
  .contactSubheading {
    font-size: 32px;
  }
  .contactButton {
    width: 140px;
    height: 40px;
    font-size: 16px;
  }
  .locationImg,
  .phoneIcon {
    width: 24px;
    height: 40px;
  }
  .locationDiscriptions {
    font-size: 16px;
  }
}

/* For screens smaller than 425px */
@media (max-width: 425px) {
  .contactContainer {
    width: 95%;
  }
  .contactHeading,
  .contactSubheading {
    font-size: 28px;
  }
  .contactButton {
    width: 120px;
    height: 38px;
    font-size: 14px;
  }
  .contactItem {
    flex-direction: column;
    align-items: flex-start;
  }
  .locationImg,
  .phoneIcon {
    width: 20px;
    height: 35px;
  }
  .locationDiscriptions {
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
  }
}

/* For screens smaller than 375px */
@media (max-width: 375px) {
  .contactHeading,
  .contactSubheading {
    font-size: 24px;
  }
  .contactButton {
    width: 110px;
    height: 36px;
    font-size: 12px;
  }
  .locationImg,
  .phoneIcon {
    width: 18px;
    height: 20px;
  }
  .locationDiscriptions {
    font-size: 13px;
  }
}

/* For screens smaller than 320px */
@media (max-width: 320px) {
  .contactHeading,
  .contactSubheading {
    font-size: 20px;
  }
  .contactButton {
    width: 100px;
    height: 34px;
    font-size: 10px;
  }
  .locationImg,
  .phoneIcon {
    width: 16px;
    height: 2px;
  }
  .locationDiscriptions {
    font-size: 12px;
  }
}

/* this is for Form */
.form {
  max-width: 736px;
  margin: 33px auto; /* Centered the form horizontally */
  border: 2px dashed rgba(53, 138, 192, 0.4);
  border-radius: 10px;
  padding: 4rem;
}

.gridContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.gridItem {
  flex: 1 1 calc(50% - 1rem);
}

.fullWidth {
  flex: 1 1 100%;
}

.textField {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid rgba(48, 176, 199, 1);
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.textField::placeholder {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: var(--Colors-Teal, rgba(48, 176, 199, 1));
}

.button {
  background: linear-gradient(180deg, #358ac0 0%, #2d90fb 100%);
  color: #fff;
  border: 1px solid transparent;
  width: 8rem;
  height: 45px;
  padding: 12px 0;
  border-radius: 24px;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.3s ease;
}

.button:hover {
  background: transparent;
  color: black;
  border-image: linear-gradient(180deg, #358ac0 0%, #2d90fb 100%);
  border-image-slice: 1;
}

/* For screens smaller than 2560px */
@media (max-width: 2560px) {
  .form {
    padding: 3rem;
  }
  .textField {
    margin-bottom: 0.75rem;
  }
}
/* For screens smaller than 1440px */
@media (max-width: 1440px) {
  .form {
    padding: 2.5rem;
  }
  .textField {
    margin-bottom: 0.5rem;
  }
}
/* For screens smaller than 1028px */
@media (max-width: 1028px) {
  .form {
    padding: 2rem;
    margin-right: 2rem; /* Reducing right margin for smaller screens */
  }
  .textField {
    margin-bottom: 0.5rem;
  }
}
/* For screens smaller than 768px */
@media (max-width: 768px) {
  .form {
    padding: 1.5rem;
    max-width: 90%;
    margin-right: auto; /* Remove specific margin for small screens */
  }
  .gridItem {
    flex: 1 1 100%; /* Full width on small screens */
  }
}
/* For screens smaller than 425px */
@media (max-width: 425px) {
  .form {
    padding: 1rem;
    max-width: 95%;
  }
  .textField {
    margin-bottom: 0.5rem;
  }
}
/* For screens smaller than 375px */
@media (max-width: 375px) {
  .form {
    padding: 0.75rem;
    max-width: 100%;
  }
  .textField {
    margin-bottom: 0.5rem;
  }
}
/* For screens smaller than 320px */
@media (max-width: 320px) {
  .form {
    padding: 0.5rem;
    max-width: 100%;
  }
  .textField {
    margin-bottom: 0.5rem;
  }
}
