/* whyChooseUs.module.css */

.container {
  display: flex;
  height: 810px;
}

.left {
  width: 702px;
  height: 810px;
  display: flex;
  position: relative;
}

.innerImage {
  width: 461px;
  height: 610px;
  opacity: 1;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.emogi1 {
  width: 96px;
  height: 96px;
  margin-top: 201px;
  margin-left: 60px;
  gap: 0px;
  border-radius: 12px;
}

.emoji2 {
  width: 96px;
  height: 96px;
  margin-top: 498px;
  margin-left: 425px;
  gap: 0px;
  border-radius: 12px 0px 0px 0px;
  opacity: 0px;
}

.parent {
  position: relative; /* Context for absolute positioning if needed */
  width: 96px; /* Set according to your layout needs */
  height: 96px; /* Set according to your layout needs */
  display: flex; /* Enable flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-top: 350px; /* Adjust as needed */
  margin-left: 400px; /* Adjust as needed */
}

.right {
  width: 702px;
  height: 803px;
  margin-left: 30px;
  gap: 32px;
}

.headingButton {
  width: 184px;
  height: 43px;
  padding: 8px 16px;
  border: none;
  border-radius: 24px;
  background: rgba(232, 246, 255, 1);
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;
  color: rgba(5, 84, 242, 1);
}

.heading {
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 44px;
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
  color: #000;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
}

.row {
  display: flex;
  width: 100%;
  gap: 24px;
  margin-bottom: 24px;
}

.card {
  flex: 1;
  border-radius: 8px;
  width: 302px;
  height: 215px;
  padding: 24px 24px 14px 24px;
  gap: 24px;
  box-shadow: 0px 5px 10px 0px rgba(49, 49, 49, 0.3);
}

.cardIcon {
  width: 25px;
  height: 25px;
  margin-left: 5px;
  margin-top: 5px;
}

.innerHeading {
  margin-left: 5px;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
}

.cardContent {
  margin-left: 5px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
}

/* Media queries for different screen sizes */
@media (max-width: 1512px) {
  .container {
    height: 70vh;
  }
}

@media (max-width: 1200px) {
  .container {
    height: 65vh;
  }
}

@media (max-width: 1024px) {
  .row {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    height: auto;
  }

  .left {
    height: 50vh;
  }

  .right {
    padding: 16px;
  }

  .row {
    flex-direction: column;
    gap: 16px;
  }
}

@media (max-width: 480px) {
  .left img,
  .whyImage {
    width: 100%;
    height: auto;
    position: static;
    transform: none;
    top: auto;
    left: auto;
  }
}
