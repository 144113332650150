.form {
  max-width: 736px;
  margin: 33px auto; /* Centered the form horizontally */
  border: 2px dashed rgba(53, 138, 192, 0.4);
  border-radius: 10px;
  padding: 4rem;
}

.gridContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.gridItem {
  flex: 1 1 calc(50% - 1rem);
}

.fullWidth {
  flex: 1 1 100%;
}

.textField {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid rgba(48, 176, 199, 1);
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.textField::placeholder {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: var(--Colors-Teal, rgba(48, 176, 199, 1));
}

.button {
  background: linear-gradient(180deg, #358ac0 0%, #2d90fb 100%);
  color: #fff;
  border: 1px solid transparent;
  width: 8rem;
  height: 45px;
  padding: 12px 0;
  border-radius: 24px;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.3s ease;
}

.button:hover {
  background: transparent;
  color: black;
  border-image: linear-gradient(180deg, #358ac0 0%, #2d90fb 100%);
  border-image-slice: 1;
}

/* For screens smaller than 2560px */
@media (max-width: 2560px) {
  .form {
    padding: 3rem;
  }
  .textField {
    margin-bottom: 0.75rem;
  }
}
/* For screens smaller than 1440px */
@media (max-width: 1440px) {
  .form {
    padding: 2.5rem;
  }
  .textField {
    margin-bottom: 0.5rem;
  }
}
/* For screens smaller than 1028px */
@media (max-width: 1028px) {
  .form {
    padding: 2rem;
    margin-right: 2rem; /* Reducing right margin for smaller screens */
  }
  .textField {
    margin-bottom: 0.5rem;
  }
}
/* For screens smaller than 768px */
@media (max-width: 768px) {
  .form {
    padding: 1.5rem;
    max-width: 90%;
    margin-right: auto; /* Remove specific margin for small screens */
  }
  .gridItem {
    flex: 1 1 100%; /* Full width on small screens */
  }
}
/* For screens smaller than 425px */
@media (max-width: 425px) {
  .form {
    padding: 1rem;
    max-width: 95%;
  }
  .textField {
    margin-bottom: 0.5rem;
  }
}
/* For screens smaller than 375px */
@media (max-width: 375px) {
  .form {
    padding: 0.75rem;
    max-width: 100%;
  }
  .textField {
    margin-bottom: 0.5rem;
  }
}
/* For screens smaller than 320px */
@media (max-width: 320px) {
  .form {
    padding: 0.5rem;
    max-width: 100%;
  }
  .textField {
    margin-bottom: 0.5rem;
  }
}
