/* Responsive Image */
.responsiveImage {
  max-width: 100px;
  width: 100%;
  border-radius: 50%;
  margin-right: 50px;
  margin-top: -20px;
}

@media screen and (max-width: 768px) {
  .responsiveImage {
    max-width: 80px;
    margin-right: 20px;
    margin-top: -10px;
  }
}

@media screen and (max-width: 480px) {
  .responsiveImage {
    max-width: 60px;
    margin-right: 0;
    margin-top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Form Parent Container */
.formParentContainer {
  margin-left: 1%;
  margin-right: 1%;
  border: 3px solid black;
}

@font-face {
  font-family: "swathi1";
  src: url("../FontFiles/ITC-Zapf-Chancery-Bold.ttf");
}

.subMain {
  text-transform: capitalize;
  font-family: "swathi1";
  font-size: 13px;
  padding: 0px;
}

@font-face {
  font-family: "CustomFont";
  src: url("../FontFiles/unicode.revueb.ttf") format("truetype");
}

@font-face {
  font-family: "CustomFonts";
  src: url("../FontFiles/ZapfChanceryItcT-Demi.ttf") format("truetype");
}

@font-face {
  font-family: "CSTM";
  src: url("../FontFiles/FontsFree-Net-arial-bold.ttf") format("truetype");
}

h3 {
  margin-bottom: 20px;
}

h5 {
  text-align: end;
  font-size: larger;
  margin-right: 0.5%;
  margin-top: -5%;
  font-family: "CustomFonts";
}

.address {
  font-size: 13px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  padding: 0px;
}

.address1 {
  font-size: 13px;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.phNo {
  font-size: 13px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
}

.colan {
  font-size: 13px;
  font-weight: bold;
  margin-right: 6px;
  margin-top: 13px;
}

.dateContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
  align-items: center;
}

.headerContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerText {
  font-size: 40px; /* Adjusted from 72px */
  color: red;
  font-family: "CustomFont";
  font-weight: bolder;
  text-align: center;
}

.enlighten {
  font-size: 13px;
  color: black;
  font-weight: 600;
  font-style: oblique;
  display: flex;
  justify-content: flex-end;
}

.formContainer {
  margin: 20px;
}

@media screen and (max-width: 300px) {
  .formContainer {
    margin: 10px;
  }
}

.preliminary {
  display: flex;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.formContents {
  margin: 25px;
}

.formFieldText {
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-content: left;
  font-family: "Roboto", sans-serif;
}

.dateText {
  height: 25px;
  border: none;
  border-bottom: 2px dotted black;
}

.dateText:focus {
  outline: none;
  border: none;
  border-bottom: 2px dotted black;
}

.inputField {
  height: 25px;
  border: none;
  border-bottom: 2px solid black;
}

.inputFieldAddress {
  display: flex;
  justify-content: flex-end;
  width: 99%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  height: 25px;
  border: none;
  border-bottom: 2px solid black;
}

.inputField:focus {
  outline: none;
  border: none;
  border-bottom: 2px solid black;
}

.fieldContainer {
  display: grid;
  grid-template-columns: 5fr 7fr;
  margin-top: 10px;
}

.colanMobile {
  display: none;
}

@media screen and (max-width: 700px) {
  .fieldContainer {
    grid-template-columns: 1fr;
  }
  .colan {
    display: none !important;
  }
  .colanMobile {
    display: block !important;
    font-size: 13px;
    font-weight: bold;
    margin-right: 6px;
  }
  .inputFieldAddress {
    margin-left: 0px;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .preliminary {
    font-size: 13px;
    font-weight: bold;
  }
}

.forMobileView {
  width: 100%;
  display: flex;
  align-items: center;
}

.tableContainer {
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
}

.tableInput {
  border: none;
}

.tableInput:focus {
  outline: none;
  border: none;
}

.tableRow {
  text-align: center;
  height: 30px;
  border: 1px solid black;
}

.tableColumn {
  border: 1px solid black;
  padding-left: 5px;
  padding-right: 5px;
}

.checkBoxLable {
  font-weight: bold;
  display: flex;
  font-family: "Roboto", sans-serif;
}

.checkBoxContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.formFields {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: -2px;
  column-gap: 4px;
  margin-top: 8px;
}

.phNoContainer {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .formContainer {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  .headerText {
    font-size: 18px; /* Adjusted from 20px */
  }
}

@media screen and (max-width: 730px) {
  .headerText {
    font-size: 18px; /* Adjusted from 20px */
    justify-content: center;
  }
}
