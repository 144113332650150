.container {
    width: 100%;
    max-width: 1512px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  
  .heading {
    font-family: "Bitter", serif;
    font-size: 74px;
    font-weight: 700;
    line-height: 73.2px;
    text-align: left;
    width: 50%;
    opacity: 1;
  }
  
  .descriptions {
    font-family: "Poppins";
    font-size: 23.5px;
    font-weight: 400;
    line-height: 29.25px;
    text-align: left;
    width: 50%;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }
  
  .buttonCover {
    display: flex;
    gap: 16px;
    margin-top: 20px;
    opacity: 1;
  }
  
  .buttonCover > button {
    padding: 16px 24px;
    border-radius: 32px;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    cursor: pointer;
  }
  
  .buttonCover > :first-child {
    color: white;
    background-color: rgba(5, 84, 242, 1);
    border: none;
  }
  
  .buttonCover > :nth-child(2) {
    color: rgba(5, 84, 242, 1);
    background-color: white;
    border: 2px solid rgba(5, 84, 242, 1);
    display: flex;
    align-items: center;
  }
  
  .buttonCover > :nth-child(2) img {
    margin-left: 8px;
  }
  
  .heroBg {
    width: 100%;
    max-width: 1499px;
    height: 700px;
    margin: 19px 45px 30px 45px;
    opacity: 1;
  }
  
  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  
  .arrow {
    position: absolute;
    top: 10px;
    right: 30px;
    bottom: 16px;
    width: 50px;
    height: 50px;
  }