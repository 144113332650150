

/* Base styles */
.sliderContainer {
  width: 100%; /* Full width by default */
  height: auto; /* Adjust height automatically */
  position: relative;
}

/* Styles for large screens (desktops) */
@media (min-width: 1200px) {
  .sliderContainer {
    width: 1388px;
    height: 751px;
  }
}

/* Styles for medium screens (tablets) */
@media (min-width: 768px) and (max-width: 1199px) {
  .sliderContainer {
    width: 80%; /* Adjust width as needed */
    height: 60vh; /* Adjust height as needed */
  }
}

/* Styles for small screens (mobiles) */
@media (max-width: 767px) {
  .sliderContainer {
    width: 100%; /* Full width for small screens */
    height: 50vh; /* Adjust height as needed */
  }
}
