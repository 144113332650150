.container {
  display: flex;
  align-items: center;
  gap: 40px;
}

.imageContainer {
  flex: 0 0 auto;
}

.image {
  width: 750px;
  height: 600px;
  margin-top: 90px;
  margin-left: 45px;
  gap: 0px;
  opacity: 0px;
}

.contentContainer {
  flex: 1;
  margin-left: 40px;
}

.title {
  display: block;
  font-family: Bitter;
  font-size: 58px;
  font-weight: 700;
  line-height: 75.4px;
  color: rgba(49, 49, 49, 1);
  font-family: Bitter;
  width: 508px;
  height: 225px;
  gap: 0px;
  opacity: 0px;

  text-align: left;
}

.description {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(49, 49, 49, 1);
  margin-top: 20px;
  width: 581px;
  height: 96px;
  gap: 0px;
  opacity: 0px;

  text-align: left;
}

.buttonCover {
  display: flex;
  gap: 16px;
  margin-top: 20px;
  opacity: 1;
}

.buttonCover > button {
  padding: 16px 24px;
  border-radius: 32px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  cursor: pointer;
}

.buttonCover > :first-child {
  color: white;
  background-color: rgba(5, 84, 242, 1);
  border: none;
}

.buttonCover > :nth-child(2) {
  color: rgba(5, 84, 242, 1);
  background-color: white;
  border: 2px solid rgba(5, 84, 242, 1);
  display: flex;
  align-items: center;
}

.buttonCover > :nth-child(2) img {
  margin-left: 8px;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.arrow {
  position: absolute;
  top: 10px;
  right: 30px;
  bottom: 16px;
  width: 50px;
  height: 50px;
}
