/* .navbarStyle {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  width: 100%;
}

.navContainer {
  width: 100%;
  max-width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
  border: 2px solid red;
}

.logo {
  flex: 1;
  position: relative;
}

.logoImg {
  width: 61px;
  height: 59px;
  position: absolute;
  top: 50%;
  left: 58px;
  transform: translateY(-50%);
}

.navLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-right: 20px;
  border: 2px solid black;
}

.navLink {
  text-decoration: none;
  color: black;
  font-weight: bold;
  position: relative;
  transition: all 0.2s ease;
}

.navLinks a {
  font-family: Roboto;
  font-size: 39px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
}

.navLink::after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: rgba(45, 144, 251, 1);
  transition: width 0.2s ease, background-color 0.2s ease;
}

.navLink:hover {
  color: rgba(45, 144, 251, 1);
  font-size: 18px;
  font-weight: bold;
}

.navLink:hover::after {
  width: 100%;
  left: 0;
  background: rgba(45, 144, 251, 1);
}

.contactButtonWrapper {
  margin-left: 240px;
}

.contactButton {
  width: 100px;
  height: 40px;
  gap: 10px;
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid rgba(45, 144, 251, 1);
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.contactButton:hover {
  background-color: rgba(45, 144, 251, 1);
  color: rgba(255, 255, 255, 1);
}

.contactButton button span {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;
}

.menuIcon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  background-color: rgba(45, 144, 251, 1);
  color: white;
  padding: 10px;
  border-radius: 50%; /* Optional: round icon 
}

.menuContent {
  display: none;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.menuContent.show {
  display: flex;
}


@media (max-width: 768px) {
  .navLinks,
  .contactButtonWrapper {
    display: none;
  }

  .menuIcon {
    display: block;
  }

  .navContainer {
    padding: 0 15px;
    flex-direction: row;
    justify-content: space-between;
  }
}


@media (max-width: 480px) {
  .navContainer {
    padding: 0 10px;
  }

  .logoImg {
    width: 50px;
    height: 47px;
    left: 40px;
  }

  .navLinks a {
    font-size: 14px;
  }

  .contactButton {
    width: 80px;
    height: 30px;
  }
} */

.navbarStyle {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  width: 100%;
}

.navContainer {
  width: 100%;
  max-width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

.logo {
  flex: 1;
  position: relative;
}

.logoImg {
  width: 61px;
  height: 59px;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

.navLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin: auto;
  flex: 2; /* Allow it to take up more space */
}

.navLink {
  text-decoration: none;
  color: black;
  font-weight: bold;
  position: relative;
  transition: all 0.2s ease;
}

.navLinks a {
  font-family: Roboto;
  font-size: 19px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
}

.navLink::after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: rgba(45, 144, 251, 1);
  transition: width 0.2s ease, background-color 0.2s ease;
  cursor: pointer;
}

.navLink:hover {
  color: rgba(45, 144, 251, 1);
  font-size: 15px;
  font-weight: bold;
}

.navLink:hover::after {
  width: 100%;
  left: 0;
  background: rgba(45, 144, 251, 1);
}

.contactButtonWrapper {
  margin-left: 240px;
}

.contactButton {
  width: 100px;
  height: 40px;
  gap: 10px;
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid rgba(45, 144, 251, 1);
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.contactButton:hover {
  background-color: rgba(45, 144, 251, 1);
  color: rgba(255, 255, 255, 1);
}

.contactButton button span {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;
}

.menuIcon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  /* padding: 10px; */
  border-radius: 50%; /* Optional: round icon */
}
.CName {
  display: none;
}

.menuContent {
  display: none;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 4px 8px rgba(135, 67, 67, 0.1);
  padding: 10px;
}

.menuContent.show {
  display: flex;
}

/* Adjustments for tablets (e.g., 1024px and below) */
@media screen and (max-width: 1024px) {
  .navLinks {
    display: flex;
    gap: 20px;
    margin: auto;
    justify-content: center;
    align-items: center;
  }

  .contactButton {
    margin-right: 40px;
  }
  .contactButtonWrapper {
    margin-left: 100px;
  }
}

/* Adjustments for tablets (e.g., 768px and below) */
/* Adjustments for tablets (e.g., 768px and below) */
@media (max-width: 768px) {
  .navLinks,
  .contactButtonWrapper {
    display: none;
  }

  .menuIcon {
    display: block;
  }

  .CName {
    display: flex;

    /* width: 100%; */
    margin-left: 20px;
    text-align: flex-start;
    float: left;
    margin-left: 75px;
    font-size: 20px; /* Adjusted from 72px */
    color: red;
    font-family: "CustomFont";
    font-weight: bolder;
  }

  .navContainer {
    padding: 0 15px;
    flex-direction: row;
    justify-content: space-between;
  }
}

/* Adjustments for small screens (e.g., 480px and below) */
@media (max-width: 480px) {
  .navContainer {
    padding: 0 10px;
  }

  .CName {
    margin-left: 20px;
    text-align: flex-start;
    justify-content: center;
    align-items: center;
    float: left;
    margin-left: 60px;
    font-size: 15px; /* Adjusted from 72px */
    color: red;
    font-family: "CustomFont";
    font-weight: bolder;
  }

  .logoImg {
    width: 50px;
    height: 47px;
    left: 0;
  }

  .navLinks a {
    font-size: 14px;
  }

  .contactButton {
    width: 80px;
    height: 30px;
  }
}
