.mainContent {
  width: 100%;
  max-width: 100%;
  height: 751px;
  margin: 2% auto 0;
  padding-left: 5%;
  gap: 0;
  opacity: 1; /* Changed from 0px to 1 for visibility */
  box-sizing: border-box;
  display: flex;
}

.innerContent {
  width: 507px;
  height: 591px;
  margin-top: 28px;
  gap: 32px;
  opacity: 1; /* Changed from 0px to 1 for visibility */
}

.navHeading {
  width: 507px;
  height: 300px;
  gap: 0;
  opacity: 1; /* Changed from 0px to 1 for visibility */
  font-family: Bitter;
  font-size: 48px;
  font-weight: 700;
  line-height: 55.4px;
  text-align: left;
}

.navDiscription {
  width: 507px;
  height: 96px;
  gap: 0;
  margin-top: 24px;
  opacity: 1; /* Changed from 0px to 1 for visibility */
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(49, 49, 49, 1);
}

.heroButton {
  width: 350px;
  margin-top: 32px;
  display: flex;
  gap: 16px;
  opacity: 1;
}

/* Style the first child button */
.heroButton > button:first-child {
  width: 152px;
  height: 59px;
  padding: 16px 24px;
  gap: 8px;
  border-radius: 32px;
  border: 0;
  background: rgba(5, 84, 242, 1);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: center; /* Changed from left to center */
  color: rgba(255, 255, 255, 1);
  transition: background-color 0.3s, color 0.3s, border 0.3s; /* Smooth transition for hover effect */
}

/* Style the first child button on hover */
.heroButton > button:first-child:hover {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(5, 84, 242, 1);
  color: rgba(5, 84, 242, 1);
}

/* Style the second child button */
.heroButton > button:nth-child(2) {
  width: 182px;
  height: 59px;
  padding: 16px 24px;
  gap: 6px;
  border-radius: 32px;
  border: 1px solid rgba(5, 84, 242, 1);
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: center; /* Changed from left to center */
  color: rgba(5, 84, 242, 1);
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

/* Style the second child button on hover */
.heroButton > button:nth-child(2):hover {
  background: rgb(49, 118, 255);
  border: 0;
  color: rgba(255, 255, 255, 1);
}

.heroButton > button:nth-child(2) span {
  display: flex;
  align-items: center;
}

.heroButton > button:nth-child(2) img {
  width: 24px;
  height: 24px;
}

.users {
  width: 507px;
  height: 48px;
  margin-top: 32px;
  opacity: 1; /* Ensure the container is visible */
  position: relative;
  display: flex;
  align-items: center; /* Center images vertically */
}

.users img {
  width: 48px;
  height: 48px;
  margin-left: -20px; /* Create the overlap */
  border-radius: 50%; /* Make the image circular */
  border: 3px solid rgba(232, 246, 255, 1);
  object-fit: cover; /* Ensure the image covers the circle */
}

.users img:first-child {
  margin-left: 0; /* Remove negative margin for the first image */
}

.usetSum {
  width: 283px;
  height: 45px;
  gap: 0;
  opacity: 1; /* Ensure the container is visible */
  margin-left: 16px;
}

.usetSum > span:first-child {
  display: inline-block; /* Allows setting width and height */
  width: 283px;
  opacity: 1; /* Ensure the element is visible */
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 0;
  text-align: left;
}

.usetSum > span:nth-child(2) {
  display: inline-block; /* Allows setting width and height */
  width: 283px;
  height: 18px;
  opacity: 1; /* Ensure the element is visible */
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 0;
  text-align: left;
  color: rgba(5, 84, 242, 1);
}

.secondContainer {
  margin-left: 85px;
  height: 627px;
  width: 639px;
  display: flex;
}

.hero1 {
  width: 288px;
  height: 457px;
  margin-top: 124px;
  gap: 0;
  margin-left: 0;
  opacity: 1; /* Changed from 01x to 1 */
}

.hero2 {
  width: 306px;
  height: 456px;
  margin-left: 20px;
  margin-bottom: 150px;
  gap: 0;
  opacity: 1; /* Changed from 0px to 1 for visibility */
}

/* Media Queries */

/* Adjustments for smaller laptops (e.g., 1366x768) */
@media (max-width: 1366px) {
  .navContainer {
    padding: 0 30px;
  }

  .navLinks a {
    font-size: 16px;
  }

  .contactButton {
    width: 90px;
    height: 35px;
  }
}

/* Adjustments for medium laptops and larger tablets (e.g., 1024x768) */
@media (max-width: 1024px) {
  .navContainer {
    padding: 0 20px;
  }

  .navLinks {
    gap: 15px;
  }

  .contactButton {
    width: 85px;
    height: 30px;
  }

  .heroButton > button:first-child {
    width: 140px;
  }

  .heroButton > button:nth-child(2) {
    width: 170px;
  }
}

/* Adjustments for tablets (e.g., 768x1024) */
@media (max-width: 768px) {
  .navContainer {
    padding: 0 15px;
    flex-direction: column;
  }

  .navLinks {
    flex-direction: column;
    gap: 10px;
  }

  .contactButtonWrapper {
    margin-top: 10px;
  }

  .heroButton > button:first-child {
    width: 130px;
  }

  .heroButton > button:nth-child(2) {
    width: 160px;
  }
}

/* Adjustments for smaller tablets and large mobile screens (e.g., 600x900) */
@media (max-width: 600px) {
  .navContainer {
    padding: 0 10px;
  }

  .logoImg {
    width: 50px;
    height: 47px;
    left: 40px;
  }

  .navLinks a {
    font-size: 14px;
  }

  .contactButton {
    width: 80px;
    height: 30px;
  }

  .heroButton > button:first-child {
    width: 120px;
  }

  .heroButton > button:nth-child(2) {
    width: 150px;
  }

  .users img {
    width: 40px;
    height: 40px;
  }

  .usetSum > span:first-child {
    font-size: 16px;
  }

  .usetSum > span:nth-child(2) {
    font-size: 12px;
  }
}

/* Adjustments for mobile screens (e.g., 480x800) */
@media (max-width: 480px) {
  .navContainer {
    padding: 0 5px;
  }

  .logoImg {
    width: 40px;
    height: 38px;
    left: 30px;
  }

  .navLinks a {
    font-size: 12px;
  }

  .contactButton {
    width: 70px;
    height: 25px;
  }

  .heroButton > button:first-child {
    width: 100px;
  }

  .heroButton > button:nth-child(2) {
    width: 130px;
  }

  .users img {
    width: 35px;
    height: 35px;
  }

  .usetSum > span:first-child {
    font-size: 14px;
  }

  .usetSum > span:nth-child(2) {
    font-size: 10px;
  }
}

/* Adjustments for very small mobile screens (e.g., 320x480) */
@media (max-width: 320px) {
  .navContainer {
    padding: 0 5px;
  }

  .logoImg {
    width: 30px;
    height: 28px;
    left: 20px;
  }

  .navLinks a {
    font-size: 10px;
  }

  .contactButton {
    width: 60px;
    height: 20px;
  }

  .heroButton > button:first-child {
    width: 90px;
  }

  .heroButton > button:nth-child(2) {
    width: 120px;
  }

  .users img {
    width: 30px;
    height: 30px;
  }

  .usetSum > span:first-child {
    font-size: 12px;
  }

  .usetSum > span:nth-child(2) {
    font-size: 8px;
  }
}
