/* Base styles for large screens */
.container {
  width: 100%; /* Width will be 100% of the screen */
  max-width: 1514px; /* Maximum width */
  height: 317px;
  top: 4885px;
  gap: 0px;
  opacity: 0px;
  background: rgba(52, 52, 52, 1);
}

.innerContainer {
  width: 100%; /* Width will be 100% of the screen */
  max-width: 1354px; /* Maximum width */
  height: 172px;
  gap: 0px;
  justify-content: space-between;
  margin: 0 auto; /* Center the container */
  opacity: 0px;
  display: flex;
}

.header {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.firstCard {
  display: flex;
  flex-direction: column;
  width: 124px; /* Fixed width */
  height: 172px;
  gap: 16px;
  opacity: 0px;
  margin-left: 40px;
  margin-top: 40px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.secondCard {
  width: 519px; /* Fixed width */
  height: 121px;
  gap: 16px;
  opacity: 1;
  margin-top: 40px;
  margin-left: 267px;
  display: flex;
  flex-direction: column;
}

.searchHeader {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.inputContainer {
  position: relative;
  width: 100%;
  height: 49px; /* Height of the input box */
  margin-top: 10px;
}

.inputBox {
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 120px; /* Make room for the button */
  border-radius: 16px;
  background: rgba(255, 255, 255, 1);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  box-sizing: border-box;
}

.subscribeButton {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  height: 36px;
  padding: 0 16px;
  border-radius: 16px;
  border: none;
  background-color: #007bff; /* Adjust the color as needed */
  color: white;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.subscribeButton:hover {
  background-color: #eef0f3; /* Darker shade for hover effect */
  color: #007bff;
}

.copyright {
  width: 100%;
  padding: 20px 0;
  margin-top: 40px;
  background: rgba(52, 52, 52, 1);
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}

/* Medium screens (e.g., tablets) */
@media (max-width: 1024px) {
  .container {
    width: 100%;
    max-width: 1200px; /* Maximum width for medium screens */
    height: auto;
    top: auto;
  }

  .innerContainer {
    width: 100%;
    max-width: 1100px; /* Maximum width for inner container */
    margin: 0 auto; /* Center the container */
    flex-direction: column;
    align-items: center;
  }

  .firstCard,
  .secondCard {
    width: 100%; /* Make the cards take full width */
    margin-left: 0;
    margin-top: 20px;
  }

  .secondCard {
    width: 100%; /* Make the second card take full width */
    margin-left: 0;
  }
}

/* Small screens (e.g., mobile devices) */
@media (max-width: 768px) {
  .container {
    width: 100%;
    max-width: 800px; /* Maximum width for small screens */
    height: auto;
    top: auto;
  }

  .innerContainer {
    width: 100%;
    max-width: 700px; /* Maximum width for inner container */
    margin: 0 auto; /* Center the container */
    flex-direction: column;
    align-items: center;
  }

  .firstCard,
  .secondCard {
    width: 100%; /* Make the cards take full width */
    margin-left: 0;
    margin-top: 20px;
  }

  .header {
    font-size: 16px;
    line-height: 22px;
  }

  .firstCard {
    font-size: 14px;
    line-height: 20px;
  }

  .secondCard {
    width: 100%; /* Make the second card take full width */
    margin-left: 0;
  }
}

/* Extra small screens (e.g., small mobile devices) */
@media (max-width: 480px) {
  .container {
    width: 100%;
    max-width: 400px; /* Maximum width for extra small screens */
    height: auto;
    top: auto;
  }

  .innerContainer {
    width: 100%;
    max-width: 350px; /* Maximum width for inner container */
    margin: 0 auto; /* Center the container */
    flex-direction: column;
    align-items: center;
  }

  .firstCard,
  .secondCard {
    width: 100%; /* Make the cards take full width */
    margin-left: 0;
    margin-top: 20px;
  }

  .header {
    font-size: 14px;
    line-height: 20px;
  }

  .firstCard {
    font-size: 12px;
    line-height: 18px;
  }

  .secondCard {
    width: 100%; /* Make the second card take full width */
    margin-left: 0;
  }
}
